import styled from 'styled-components'
import { Link } from 'gatsby'

export const HeaderContainer = styled.header`
  position: sticky;
  width: 100%;
  height: 70px;
  background-color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  position: relative;

  @media (min-width: 756px) {
    padding: 20px 10%;
    height: 80px;
  }
`

export const IconContainer = styled.div`
  color: ${props => props.theme.colors.primary};
  font-size: 26px;
  cursor: pointer;
  svg {
    margin-left: 10px;
  }
`

interface NavContainerProps {
  active: boolean
}

export const NavContainer = styled.nav<NavContainerProps>`
  background-color: ${props => props.theme.colors.primary};
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transform: ${props =>
    props.active ? 'translate3d(0, 0, 0)' : 'translate3d(100%, 0, 0)'};
  z-index: 10;
  transition: 0.3s ease all;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`

export const NavClose = styled.div`
  height: 80px;
  color: ${props => props.theme.colors.white};
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.white};
  font-size: 22px;
  text-transform: uppercase;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 20px;
  border-bottom: 1px solid ${props => props.theme.colors.white};
  padding-bottom: 5px;
  margin-right: 10px;
  @media (min-width: 765px) {
    color: ${props => props.theme.colors.primary};
    font-size: 16px;
    margin: 0;
    text-transform: none;
    padding: 0;
    margin-right: 10px;
  }
`

export const NavHorizontal = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
`

export const Alert = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  width: 100%;
  height: 35px;
  font-family: 'Roboto', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
`

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.primary};
  font-size: 26px;
  cursor: pointer;
  svg {
    margin-left: 10px;
  }
`
export const SearchInput = styled.input`
  border: none;
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  outline: none;
  color: ${props => props.theme.colors.primary};
  font-size: 16px;
  padding-bottom: 2px;
  width: 100px;
  @media (min-width: 765px) {
    width: 100px;
  }
`
