import * as React from 'react'

import Header from 'containers/Header'
import Footer from 'containers/Footer'
import LayoutContainer from 'containers/LayoutContainer'

interface Props {
  children: React.ReactChild
}

const Layout = ({ children }: Props) => {
  return (
    <LayoutContainer>
      <div>
        <Header />
        {children}
      </div>
      <Footer />
    </LayoutContainer>
  )
}

export default Layout
