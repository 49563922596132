import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { MdMenu, MdClose } from 'react-icons/md'
import {
  AiFillFacebook,
  AiFillInstagram,
  AiOutlineSearch,
  AiOutlineWhatsApp,
} from 'react-icons/ai'
import { isMobile } from 'react-device-detect'

import Logo from 'components/Logo'

import headerOptions from './HeaderOptions'

import {
  HeaderContainer,
  IconContainer,
  NavContainer,
  NavClose,
  StyledLink,
  NavHorizontal,
  SearchContainer,
} from './Header.styles'

const Header = () => {
  const [searchValue, setSearchValue] = useState('')
  const [isNavActive, setIsNavActive] = useState(false)

  return (
    <>
      <HeaderContainer>
        <Logo />
        {isMobile && (
          <>
            <SearchContainer>
              <AiOutlineSearch
                onClick={() =>
                  window.open(
                    `https://resultados.drnuvem.com.br/${searchValue}`
                  )
                }
              />
            </SearchContainer>
            <IconContainer onClick={() => setIsNavActive(!isNavActive)}>
              <AiFillFacebook
                onClick={() =>
                  navigate('https://www.facebook.com/VeterinariaDerosso/')
                }
              />
              <AiFillInstagram
                onClick={() =>
                  navigate('https://www.instagram.com/veterinariaderosso/')
                }
              />
              <AiOutlineWhatsApp
                onClick={() => navigate('https://wa.me/554132758180')}
              />
              <MdMenu />
            </IconContainer>
          </>
        )}

        {isMobile ? (
          <NavContainer active={isNavActive}>
            <NavClose onClick={() => setIsNavActive(false)}>
              <MdClose />
            </NavClose>

            <>
              {headerOptions.map((option, i) => (
                <StyledLink
                  to={option.path}
                  onClick={() => setIsNavActive(false)}
                >
                  {option.title}
                </StyledLink>
              ))}
            </>
          </NavContainer>
        ) : (
          <NavHorizontal>
            {headerOptions.map((option, i) => (
              <StyledLink
                to={option.path}
                onClick={() => setIsNavActive(false)}
              >
                {option.title}
              </StyledLink>
            ))}
            <SearchContainer>
              <AiOutlineSearch
                onClick={() =>
                  window.open(
                    `https://resultados.drnuvem.com.br/${searchValue}`
                  )
                }
              />
            </SearchContainer>
            <IconContainer>
              <AiFillFacebook
                onClick={() =>
                  navigate('https://www.facebook.com/VeterinariaDerosso/')
                }
              />
              <AiFillInstagram
                onClick={() =>
                  navigate('https://www.instagram.com/veterinariaderosso/')
                }
              />
              <AiOutlineWhatsApp
                onClick={() => navigate('https://wa.me/554132758180')}
              />
            </IconContainer>
          </NavHorizontal>
        )}
      </HeaderContainer>
    </>
  )
}

export default Header
