import React from 'react'
import { Link } from 'gatsby'

import { FooterContainer, SectionContainer } from './Footer.styles'

const Footer = () => {
  return (
    <FooterContainer>
      <SectionContainer>
        <h2>INFORMAÇÕES DE CONTATO</h2>
        <span>Telefone</span>
        <a href='tel:4132758180'>(41) 3275-8180</a>
        <span>WhatsApp</span>
        <a href='https://wa.me/554132758180'>+554132758180</a>
        <span>E-mail</span>
        <a href='mailto:clinica@veterinariaderosso.com.br'>
          clinica@veterinariaderosso.com.br
        </a>
        <span>Localização</span>
        <a href={`https://maps.google.com/?q=Clínica Veterinária Derosso`}>
          Rua Líbero Sant'Ana Nunes, 40 - Xaxim
        </a>
      </SectionContainer>
      <SectionContainer>
        <h2>MAPA DO SITE</h2>
        <Link to='/'>Home</Link>
        <Link to='/sobre-nos'>Sobre nós</Link>
        <Link to='/servicos'>Nossos serviços</Link>
        <Link to='/dicas-derosso'>Dicas Derosso</Link>
        <Link to='/galeria'>Galeria de fotos</Link>
      </SectionContainer>
    </FooterContainer>
  )
}

export default Footer
