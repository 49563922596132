import styled from 'styled-components'

export const FooterContainer = styled.div`
  width: 100%;
  padding: 30px 20px 40px 20px;
  display: flex;
  flex-wrap: wrap;
  background-color: ${props => props.theme.colors.black};
  justify-content: space-between;
  position: relative;
  bottom: 0;

  @media (min-width: 765px) {
    padding: 30px 10% 40px 10%;
  }
`

export const SectionContainer = styled.div`
  width: 35%;
  margin-right: 5%;
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;

  h2 {
    text-transform: uppercase;
    color: ${props => props.theme.colors.white};
    font-size: 16px;
    margin-bottom: 15px;
  }

  span {
    color: ${props => props.theme.colors.white};
    font-size: 12px;
    margin-bottom: 5px;
  }

  a {
    color: ${props => props.theme.colors.primary};
    font-size: 12px;
    margin-bottom: 10px;
    text-decoration: none;
  }

  @media (min-width: 765px) {
    h2 {
      font-size: 24px;
    }
    span {
      font-size: 16px;
    }
    a {
      font-size: 16px;
    }
  }
`
