import styled from 'styled-components'

export const LogoContainer = styled.div`
  font-family: Helvetica, sans-serif;
  color: ${props => props.theme.colors.primary};
  font-weight: 500;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  cursor: pointer;
  span {
    position: absolute;
  }
`

export const LogoVeterinária = styled.img`
  height: 60px;
`

export const LogoDerosso = styled.span``
