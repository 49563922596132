export default [
  {
    title: 'Sobre nós',
    path: '/sobre-nos',
  },
  {
    title: 'Dicas Derosso',
    path: '/dicas-derosso',
  },
  {
    title: 'Nossos Serviços',
    path: '/servicos',
  },
  {
    title: 'Galeria de Fotos',
    path: '/galeria',
  },
  {
    title: 'Resultado de Exame',
    path: '/#resultado-de-exame',
  },
]
